@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin input-state($alpha) {
    background-color: darken-relative(
        $opds-color-background-input-default,
        $alpha
    );
}

@mixin input-sizing($dimension, $lineheight) {
    $margin: ($lineheight - $dimension) / 2;
    margin-bottom: $margin;
    margin-top: $margin;
    padding: bordered($dimension / 2);
}

@mixin selectable {
    display: flex;

    [type='checkbox'],
    [type='radio'] {
        // Width/height 1px are fixes for https://oppjira.eficode.com/browse/OPDS-2174
        // and https://oppjira.eficode.com/browse/OPDS-2337
        height: 1px;
        left: -100vw;
        opacity: 0;
        position: absolute;
        width: 1px;

        &:active + :hover::before {
            @include input-state($opds-misc-alpha-active-default);
            border-color: $opds-color-border-input-hover;
        }

        &:focus + ::before {
            @include input-state($opds-misc-alpha-hover-default);
            border-color: $opds-color-border-input-hover;
            // box-shadow is used, because css doesn't support rounded outlines
            box-shadow: 0 0 0 $opds-width-outline-input-focus
                $opds-color-outline-input-focus;
        }

        &:focus + label {
            outline: $opds-color-outline-focus $opds-style-outline-focus
                $opds-width-outline-focus;
        }
    }

    &--invalid {
        [type='checkbox'],
        [type='radio'] {
            &:focus + ::before,
            &:focus + :hover::before {
                // box-shadow is used, because css doesn't support rounded outlines
                box-shadow: 0 0 0 $opds-width-outline-input-focus
                        $opds-color-outline-input-focus,
                    0 0 gu(2)
                        rgba(
                            $opds-color-indicator-error,
                            $opds-misc-alpha-darken-400 / 100%
                        );
            }
        }
    }

    &__label {
        align-items: flex-start;
        color: $opds-color-text-large;
        cursor: pointer;
        display: flex;
        font-size: $opds-font-size-medium;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        padding: $opds-space-xxs 0;

        &::before {
            @include input-sizing(gu(7), $opds-line-height-default);
            background-color: $opds-color-background-input-default;
            border-color: $opds-color-border-input-default;
            border-style: solid;
            border-width: $opds-border-width-default;
            content: '';
            display: inline-block;
            flex: none;
            margin-right: $opds-space-xs;
        }

        &:empty::before {
            margin: 0;
        }

        &:hover::before {
            @include input-state($opds-misc-alpha-hover-default);
            border-color: $opds-color-border-input-hover;
        }
    }

    &--tiny &__label {
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
        padding: 0;

        &::before {
            @include input-sizing(gu(4), $opds-line-height-default);
            margin-left: $opds-space-xxxs;
        }

        &:empty::before {
            margin: $opds-space-xxxs;
        }
    }

    &--small &__label {
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
        padding: $opds-space-xxxs 0;

        &::before {
            @include input-sizing(gu(6), $opds-line-height-default);
        }
    }

    &--large &__label,
    &--hero &__label {
        padding: $opds-space-xs 0;

        &::before {
            @include input-sizing(gu(8), $opds-line-height-default);
        }
    }

    &--disabled &__label {
        color: $opds-color-text-disabled;
        cursor: initial;
        pointer-events: none;
        text-decoration: $opds-text-decoration-disabled;

        &::before,
        &:hover::before {
            background-color: $opds-color-background-disabled;
            border-color: $opds-color-border-disabled;
        }
    }

    &--invalid &__label {
        &::before {
            background-color: $opds-color-background-input-invalid;
            border-color: $opds-color-border-input-invalid;
            box-shadow: 0 0 gu(1)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-600 / 100%
                );
        }

        &:hover::before {
            @include input-state($opds-misc-alpha-hover-default);
            border-color: $opds-color-border-input-hover;
            box-shadow: 0 0 gu(2)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-400 / 100%
                );
        }
    }

    &--buttonized {
        [type='checkbox'],
        [type='radio'] {
            // Workaround for Chrome: Handle active state separately for
            // keyboard and mouse use.
            // https://oppjira.eficode.com/browse/OPDS-2426
            &:active:focus + label,
            + label:active {
                @include input-state($opds-misc-alpha-active-default);
                border-color: $opds-color-border-button-default;
            }

            &:focus + label {
                @include input-state($opds-misc-alpha-hover-default);
                border-color: $opds-color-border-button-default;
            }

            &:active + ::before,
            &:focus + ::before {
                background-color: $opds-color-background-button-default;
            }
        }
    }

    &--buttonized &__label {
        background-color: $opds-color-background-button-default;
        border-color: $opds-color-border-button-default;
        border-radius: $opds-border-radius-default;
        border-style: solid;
        border-width: $opds-border-width-default;
        padding: $opds-space-inset-input-default;
        width: 100%;

        &::before {
            @include input-sizing(gu(5), $opds-line-height-default);
        }

        &:hover {
            @include input-state($opds-misc-alpha-hover-default);
            border-color: $opds-color-border-button-default;
        }
    }

    &--buttonized &__label:hover::before {
        background-color: $opds-color-background-input-default;
    }

    &--buttonized#{&}--disabled &__label {
        background-color: $opds-color-background-button-disabled;
        border-color: $opds-color-border-button-disabled;
    }

    &--buttonized#{&}--small &__label {
        border-radius: $opds-border-radius-small;
        padding: $opds-space-inset-input-small;
    }

    &--buttonized#{&}--large &__label,
    &--buttonized#{&}--hero &__label {
        border-radius: $opds-border-radius-default;
        padding: $opds-space-inset-input-large;

        &::before {
            @include input-sizing(gu(6), $opds-line-height-default);
        }
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &--hero &__label {
            font-size: $opds-font-size-large;
            line-height: $opds-line-height-large;
            padding: $opds-space-small 0;

            &::before {
                @include input-sizing(gu(9), $opds-line-height-large);
            }

            &:not(:empty)::before {
                margin-right: $opds-space-small;
            }
        }

        &--buttonized#{&}--hero &__label {
            border-radius: $opds-border-radius-default;
            padding: $opds-space-inset-input-hero;

            &::before {
                @include input-sizing(gu(6), $opds-line-height-large);
            }
        }
    }
}
