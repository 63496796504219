@import '@op/opds-tokens/lib/web/op/functions';

.ds-status-message {
    opacity: 0;
    transition-duration: 200ms;
    transition-property: opacity;
    transition-timing-function: linear;

    &:not(:empty) {
        opacity: 1;
    }

    &--visually-hidden {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: gu(1);
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: gu(1);
    }
}
