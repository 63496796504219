@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/icon/navigation';
@import '@op/opds-tokens/lib/web/op/icon/action';

@function default-option-item-height($size, $factor: 1) {
    @if $size == 'small' {
        @return ($opds-space-xxs * 2 + $opds-line-height-default) * $factor;
    }

    @return ($opds-space-xs * 2 + $opds-line-height-default) * $factor;
}

.ds-combobox {
    $cb: &;

    /**
     * Control
     */
    &__control {
        position: relative;
    }

    /**
     * Control "button"
     */
    &__button {
        align-items: center;
        background-color: $opds-color-background-input-default;
        background-image: opds-icon-navigation-expand($opds-color-icon-action);
        background-position: right bordered($opds-space-xs) center;
        background-repeat: no-repeat;
        background-size: $opds-size-icon-default;
        border: $opds-border-width-default solid
            $opds-color-border-input-default;
        border-radius: $opds-border-radius-default;
        color: $opds-color-text-large;
        cursor: default;
        font-family: inherit;
        font-size: $opds-font-size-medium;
        font-weight: $opds-font-weight-default;
        justify-content: center;
        line-height: $opds-line-height-default;
        padding-right: bordered($opds-size-icon-default + 2 * $opds-space-xs);
        user-select: none;
        width: 100%;

        #{$cb}--multivalue:not(#{$cb}--empty) & {
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }

        /**
         * States
         */
        #{$cb}--invalid & {
            background-color: $opds-color-background-input-invalid;
            border-color: $opds-color-border-input-invalid;
            box-shadow: 0 0 gu(1)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-600 / 100%
                );
        }

        #{$cb}--invalid &:hover,
        #{$cb}--invalid &:focus {
            box-shadow: 0 0 gu(2)
                rgba(
                    $opds-color-indicator-error,
                    $opds-misc-alpha-darken-400 / 100%
                );
        }

        &:hover,
        &:focus,
        #{$cb}--focused & {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-hover-default
            );
            border-color: $opds-color-border-input-hover;
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-active-default
            );
        }

        &:focus,
        #{$cb}--focused & {
            outline-color: $opds-color-outline-input-focus;
            outline-offset: $opds-border-width-default * 2 * -1; // Move the outline "inside and on top of" the element borders to mimic native <select>.
            outline-style: $opds-style-outline-input-focus;
            outline-width: $opds-width-outline-input-focus;
        }

        #{$cb}--disabled & {
            background-image: opds-icon-navigation-expand(
                $opds-color-icon-disabled
            );
            border-color: $opds-color-border-disabled;
            color: $opds-color-text-disabled;
            pointer-events: none;
            text-decoration: $opds-text-decoration-disabled;
        }

        /**
         * Sizes
         */
        #{$cb}--small & {
            background-position: right bordered($opds-space-xxs) center;
            background-size: $opds-size-icon-small;
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;
            padding-right: bordered(
                $opds-size-icon-small + 2 * $opds-space-xxs
            );
        }

        #{$cb}--small:not(#{$cb}--disabled) & {
            color: $opds-color-text-default;
        }

        #{$cb}--large &,
        #{$cb}--hero & {
            background-position: right bordered($opds-space-xs) center;
            background-size: $opds-size-icon-large;
            padding-right: bordered($opds-size-icon-large + 2 * $opds-space-xs);
        }

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            #{$cb}--hero & {
                background-position: right bordered($opds-space-small) center;
                background-size: $opds-size-icon-large;
                font-size: $opds-font-size-large;
                line-height: $opds-line-height-large;
                padding-right: bordered(
                    $opds-size-icon-large + 2 * $opds-space-small
                );
            }
        }
    }

    /**
     * Selected item container (single value & multivalue empty state)
     */
    &__value,
    &__placeholder {
        padding: bordered($opds-space-inset-input-default);

        #{$cb}--small & {
            padding: bordered($opds-space-inset-input-small);
            padding-right: 0;
        }

        #{$cb}--large &,
        #{$cb}--hero & {
            padding: bordered($opds-space-inset-input-large);
            padding-right: 0;
        }

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            #{$cb}--hero & {
                padding: bordered($opds-space-inset-input-hero);
                padding-right: 0;
            }
        }
    }

    /**
     * Selected items container (multivalue)
     */
    &__values {
        display: flex;
        flex-wrap: wrap;
        padding: $opds-space-xxs 0 0;
        padding-right: $opds-size-icon-default + 2 * $opds-space-xs;

        #{$cb}--small & {
            padding: $opds-space-xxxs 0 0;
            padding-right: $opds-size-icon-small + 2 * $opds-space-xxs;
        }

        #{$cb}--large &,
        #{$cb}--hero & {
            padding-bottom: $opds-space-xs - $opds-space-xxs;
            padding-left: $opds-space-xs - $opds-space-xxs;
            padding-right: $opds-size-icon-large + 2 * $opds-space-xs;
            padding-top: $opds-space-xs;
        }

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            #{$cb}--hero & {
                padding-bottom: $opds-space-small - $opds-space-xxs;
                padding-left: $opds-space-small - $opds-space-xxs;
                padding-right: $opds-size-icon-large + 2 * $opds-space-small;
                padding-top: $opds-space-small;
            }
        }
    }

    /**
     * Selected item (multivalue)
     */
    &__multivalue-item {
        background-color: $opds-color-background-tertiary;
        background-image: opds-icon-action-remove($opds-color-icon-default);
        background-position: right $opds-space-xxxs center;
        background-repeat: no-repeat;
        background-size: $opds-size-icon-small;
        border: $opds-border-width-default solid $opds-color-border-default;
        border-radius: $opds-border-radius-small;
        color: $opds-color-text-large;
        font-family: inherit;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-medium;
        line-height: $opds-line-height-default - 2 * $opds-border-width-default;
        margin: 0 0 $opds-space-xxs $opds-space-xxs;
        overflow: hidden;
        padding: 0;
        padding-left: bordered($opds-space-xxs);
        padding-right: bordered($opds-size-icon-small + 2 * $opds-space-xxxs);
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;

        /**
         * States
         */
        &:focus,
        &:hover {
            background-color: darken-relative(
                $opds-color-background-tertiary,
                $opds-misc-alpha-hover-default
            );
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-tertiary,
                $opds-misc-alpha-active-default
            );
        }

        &:focus {
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
        }

        /**
         * Sizes
         */
        #{$cb}--small & {
            margin: 0 0 $opds-space-xxxs $opds-space-xxxs;
        }

        #{$cb}--large &,
        #{$cb}--hero & {
            margin: 0 0 $opds-space-xxs $opds-space-xxs;
        }

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            #{$cb}--hero & {
                line-height: $opds-line-height-large - 2 *
                    $opds-border-width-default;
            }
        }
    }

    /**
     * Dropdown
     */
    &__portal {
        display: flex;
        flex-direction: column;
        left: 0;
        position: absolute;
        top: 0;
        z-index: 1;
    }

    &__dropdown {
        $min-height-factor: 2.2; // Factor for min height by default option item height.

        background-color: $opds-color-background-input-default;
        border-radius: $opds-border-radius-default;
        box-shadow: $opds-shadow-dropdown-default;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin: 0 ($opds-space-xxs * -1) $opds-space-xs;
        max-height: calc(100% - #{$opds-space-xs});
        min-height: default-option-item-height(_, $min-height-factor);
        padding: $opds-space-xxs 0;
        position: relative;

        &--small {
            min-height: default-option-item-height(small, $min-height-factor);
        }

        &--searchable {
            min-height: default-option-item-height(_, $min-height-factor + 1);
        }

        &#{&}--searchable#{&}--small {
            min-height: default-option-item-height(
                small,
                $min-height-factor + 1
            );
        }

        &:focus {
            outline: none;
        }
    }

    &__optionlist {
        $max-height-factor: 7.5; // Factor for max height by default option item height.

        max-height: default-option-item-height(_, $max-height-factor);
        overflow: hidden;
        position: relative;

        &#{&} {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        #{$cb}__dropdown--small & {
            max-height: default-option-item-height(small, $max-height-factor);
        }

        &:focus {
            outline: none;
        }

        #{$cb}__portal--list-overflow & {
            overflow-y: scroll;
        }
    }

    /**
     * Dropdown options
     */
    &__option#{&}__option,
    &__option-group__label#{&}__option-group__label,
    &__no-results {
        margin: 0;
        padding: $opds-space-xs $opds-space-default;

        // Reset styles from `.opds ul li:before`
        &::before {
            content: none;
        }

        #{$cb}__dropdown--small & {
            padding: $opds-space-xxs $opds-space-small;
        }
    }

    &__option {
        $divideredsmall: #{$cb}__dropdown--small#{$cb}__dropdown--dividered-options;

        color: $opds-color-text-default;
        cursor: default;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        user-select: none;

        #{$cb}__dropdown--dividered-options &:not(:last-child) {
            border-bottom: $opds-border-width-default solid
                $opds-color-border-default;
            padding-bottom: bordered($opds-space-xs);
        }

        #{$divideredsmall} &:not(:last-child) {
            padding-bottom: bordered($opds-space-xxs);
        }

        &--hidden {
            display: none;
        }

        &:hover,
        &--selected,
        #{$cb}__dropdown--focused &--focused {
            box-shadow: inset gu(1) 0 0 $opds-color-indicator-default;
        }

        #{$cb}__dropdown--focused &--focused,
        &:hover {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-hover-default
            );
        }

        #{$cb}__dropdown--focused &--focused {
            outline: $opds-width-outline-focus $opds-style-outline-focus
                $opds-color-outline-focus;
            outline-offset: $opds-width-outline-focus * -1; // Pull focus border inside parent overflow hidden.
        }

        &:active:not([aria-disabled]) {
            background-color: darken-relative(
                $opds-color-background-input-default,
                $opds-misc-alpha-active-default
            );
        }

        &--disabled {
            color: $opds-color-text-disabled;
            text-decoration: $opds-text-decoration-disabled;
        }
    }

    &__option-group {
        &#{&} {
            list-style: none;
            margin: 0;
            padding: 0;
        }
    }

    &__option-group__label {
        &#{&} {
            background-color: $opds-color-background-tertiary;
            color: $opds-color-text-label-default;
            font-size: $opds-font-size-micro;
            font-weight: $opds-font-weight-medium;
            letter-spacing: $opds-letter-spacing-caps;
            line-height: $opds-line-height-micro;
            margin: 0;
            text-transform: uppercase;
        }

        // Hide groupd with 0 options. Usually because search.
        &:first-of-type:last-of-type {
            display: none;
        }
    }

    &__search {
        margin: $opds-space-xxs $opds-space-small $opds-space-small;
    }

    &__search__input-wrap {
        background-color: $opds-color-background-input-default;
        border: $opds-border-width-default solid
            $opds-color-border-input-default;
        border-radius: 0;
        color: $opds-color-text-default;
        display: flex;
        font-family: inherit;
        font-size: $opds-font-size-medium;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;

        &:hover {
            border-color: $opds-color-border-input-hover;
        }

        #{$cb}__search--focused & {
            border-color: $opds-color-border-input-hover;
            outline: $opds-width-outline-focus $opds-style-outline-input-focus
                $opds-color-outline-input-focus;
            outline-offset: 0;
        }

        #{$cb}__search--disabled &,
        #{$cb}__search--loading & {
            background-color: $opds-color-background-disabled;
            border-color: $opds-color-border-disabled;
            color: $opds-color-text-disabled;
            // disable pointer events altogether on disabled inputs
            pointer-events: none;
        }

        #{$cb}__search--disabled & {
            text-decoration: $opds-text-decoration-disabled;
        }
    }

    &__search-input {
        border: 0;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        margin: bordered($opds-space-inset-input-default);
        margin-right: 0;
        padding: 0;
        width: 100%;

        &:focus {
            outline: 0;
        }
    }

    &__search-icon {
        padding: bordered($opds-space-xxs) bordered($opds-space-xs);
    }

    &__clear-search-button {
        &#{&} {
            margin: bordered($opds-space-xxxs);
        }
    }

    &__search-hint {
        color: $opds-color-text-secondary;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-small;
        padding-top: $opds-space-xxs;
    }

    // Visibility hidden
    &__offscreen {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: gu(1);
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: gu(1);
    }

    &__option:not(#{$cb}__option--start-match-impossible) {
        #{$cb}__option-text--highlight:not(:first-child) {
            font-weight: $opds-font-weight-bold;
        }

        #{$cb}__option-text--highlight:first-child + * {
            font-weight: $opds-font-weight-bold;
        }
    }

    &__option--start-match-impossible {
        #{$cb}__option-text--highlight {
            font-weight: $opds-font-weight-bold;
        }
    }

    &__loading-overlay {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &::after {
            background-color: $opds-color-background-input-default;
            content: '';
            height: 100%;
            left: 0;
            opacity: $opds-misc-alpha-darken-600 / 100%;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &__no-results {
        color: $opds-color-text-secondary;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        text-align: center;

        &--loading {
            margin: $opds-space-xxs 0;
            text-align: center;
        }
    }

    &--focused {
        .ds-combobox__button {
            background-image: opds-icon-navigation-collapse(
                $opds-color-icon-action
            );
        }
    }
}
