@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

$size-closebutton-default: gu(10);
$size-closebutton-flash: gu(11);

.ds-alert {
    background-color: $opds-color-background-default;
    background-image: linear-gradient(
        90deg,
        $opds-color-indicator-neutral gu(1),
        transparent 0
    );
    border-radius: $opds-border-radius-default;
    box-shadow: $opds-shadow-alert-default;
    color: $opds-color-text-default;
    display: flex;
    margin-bottom: $opds-space-large;
    overflow: hidden;
    position: relative;
    transition: opacity 0.2s ease;

    &__title#{&}__title {
        color: $opds-color-text-large;
        font-size: $opds-font-size-medium;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
        margin-bottom: $opds-space-xs;
        padding-top: 0;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-large;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-6;
            line-height: $opds-line-height-large;
        }
    }

    &__close {
        position: absolute;
        right: $opds-space-xxs;
        top: $opds-space-xxs;
    }

    &--embedded {
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: $opds-space-default;
    }

    &--embedded &__title {
        color: $opds-color-text-large;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-medium;
        letter-spacing: $opds-letter-spacing-caps;
        line-height: $opds-line-height-small;
        margin-bottom: $opds-space-xxxs;
        padding-top: 0;
        text-transform: uppercase;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-default;
            line-height: $opds-line-height-default;
        }
    }

    &--embedded &__icon {
        padding: $opds-space-xxs $opds-space-xs;
    }

    &--embedded &__body {
        padding: $opds-space-xxs ($size-closebutton-default + $opds-space-xxs)
            $opds-space-xxs 0;
    }

    &--embedded &__close {
        right: 0;
        top: 0;
    }

    &--inline,
    &--inline-large,
    &--flash {
        font-weight: $opds-font-weight-medium;
    }

    &--inline &__body,
    &--flash &__body {
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    &--inline,
    &--inline-large {
        background: none;
        box-shadow: none;
        margin-bottom: 0;
        opacity: 0;
    }

    &--inline &__icon,
    &--inline-large &__icon {
        padding: 0;
    }

    &--inline &__body,
    &--inline-large &__body {
        padding: 0 0 0 $opds-space-xxs;
    }

    &--visible {
        opacity: 1 !important;
    }

    &--inline-large &__body {
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
    }

    &--error {
        background-image: linear-gradient(
            90deg,
            $opds-color-indicator-error gu(1),
            transparent 0
        );
    }

    &--success {
        background-image: linear-gradient(
            90deg,
            $opds-color-indicator-success gu(1),
            transparent 0
        );
    }

    &__icon {
        display: flex;
        justify-content: center;
        padding: $opds-space-xs 0 0 $opds-space-small;
    }

    &__body {
        font-size: $opds-font-size-default;
        line-height: $opds-line-height-default;
        padding: $opds-space-small;
        padding-right: $opds-space-xxs + $size-closebutton-default +
            $opds-space-xxs;
        width: 100%;

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            font-size: $opds-font-size-medium;
        }
    }

    &__body p:last-child {
        margin-bottom: 0;
    }

    &--flash {
        box-shadow: $opds-shadow-alert-flash;
        left: 0;
        margin: 0 auto;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.25s ease;
        width: 10 / 12 * 100%; // grid columns
        z-index: 1;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            width: 8 / 12 * 100%; // grid columns
        }
    }

    &--flash &__body {
        padding: $opds-space-xs;
        padding-left: $opds-space-xxs;
        padding-right: $opds-space-xs + $size-closebutton-flash;
    }

    &--flash &__close {
        top: 0;
        right: 0;

        .ds-button__icon {
            margin: $opds-space-xxxs; // Increase the button size
        }
    }
}
