@import '@op/opds-tokens/lib/web/op/tokens';

.ds-list-item {
    &__icon {
        align-self: normal;
        flex-shrink: 0;
        margin-right: $opds-space-xs;
    }

    &--icon-default {
        padding-left: $opds-size-icon-default + $opds-space-xs;

        .ds-list.ds-list--border-button &,
        .ds-list.ds-list--indented & {
            padding-left: $opds-space-small + $opds-size-icon-default +
                $opds-space-xs;
        }
    }

    &--icon-default &__icon {
        margin-left: -($opds-size-icon-default + $opds-space-xs);
    }

    &--icon-large {
        padding-left: $opds-size-icon-large + $opds-space-xs;

        .ds-list.ds-list--border-button &,
        .ds-list.ds-list--indented & {
            padding-left: $opds-space-small + $opds-size-icon-large +
                $opds-space-xs;
        }
    }

    &--icon-large &__icon {
        margin-left: -($opds-size-icon-large + $opds-space-xs);
    }

    & &__content {
        align-items: center;
        color: $opds-color-text-default;
        display: block;
        flex: 0 1 100%;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
    }

    .ds-list--border-none &__action {
        flex: 0 1 100%;
    }

    .ds-list--tiny &__icon {
        margin-bottom: ($opds-line-height-small - $opds-size-icon-default) / 2;
        margin-top: ($opds-line-height-small - $opds-size-icon-default) / 2;
    }

    .ds-list--tiny &__content {
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    .ds-list--large &__content {
        color: $opds-color-text-large;
        font-size: $opds-font-size-medium;
    }

    @media only screen and (min-width: $opds-breakpoint-md-min) {
        .ds-list.ds-list--indented #{&}--icon-default {
            padding-left: $opds-space-default + $opds-size-icon-default +
                $opds-space-xs;
        }

        .ds-list.ds-list--indented #{&}--icon-large {
            padding-left: $opds-space-default + $opds-size-icon-large +
                $opds-space-xs;
        }
    }
}
