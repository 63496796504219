@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

@mixin title-styles {
    color: $opds-color-text-large;
    font-size: $opds-font-size-medium;
    font-weight: $opds-font-weight-medium;
    line-height: $opds-line-height-default;
}

@mixin large-title-styles {
    font-size: $opds-font-size-large;
}

// Outline are done with border so they are not cut by overflow: hidden.
@mixin pseudo-outline {
    border-color: $opds-color-outline-focus;
    border-radius: $opds-border-radius-default;
    border-style: $opds-style-outline-focus;
    border-width: $opds-width-outline-focus;
    bottom: 0;
    content: '';
    left: 0;
    margin: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.ds-tile {
    background-color: $opds-color-background-default;
    background-position: center;
    background-size: cover;
    border-radius: $opds-border-radius-default;
    box-shadow: $opds-shadow-tile-default;
    display: flex;
    flex-direction: column;
    margin-bottom: $opds-space-small;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        margin-bottom: $opds-space-default;
    }

    // Clearfix
    &::after {
        clear: both;
        content: '';
        display: block;
    }

    // Reset possible <li> styles.
    &#{&}::before {
        content: none;
    }

    &--link-stretched,
    &--link-wrap {
        &:focus-within,
        &:hover {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-hover-default
            );
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-active-default
            );
        }

        &:focus-within::before,
        a:hover & {
            @include pseudo-outline;
        }
    }

    /* 
     * Title
     */
    & &__title {
        // Override `.opds h2` styles.
        @include title-styles;
        margin: 0;
        padding: 0;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            @include large-title-styles;
        }
    }

    &:not(#{&}--linked) &__title {
        margin: $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            margin: $opds-space-default;
        }
    }

    /* 
     * Icon
     */
    &__icon {
        flex-shrink: 0;
        height: $opds-size-icon-default;
        margin-left: $opds-space-xs;
        width: $opds-size-icon-default;
    }

    /* 
     * Wrapping link
     */
    & &__link-wrap {
        // Override `.opds a` styles.
        background: none;
        border: 0;
        color: unset;
        cursor: pointer;
        font-size: 100%;
        font-weight: unset;
        text-align: unset;
        text-decoration: none;
        width: 100%;

        &::after {
            border-radius: $opds-border-radius-default;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    /* 
     * Link
     */
    & &__link {
        // Override `.opds a` styles.
        @include title-styles;
        background: none;
        border: 0;
        display: flex;
        justify-content: space-between;
        padding: $opds-space-small;
        text-align: unset;
        text-decoration: none;
        width: 100%;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            @include large-title-styles;
            padding: $opds-space-default;
        }

        &:hover {
            cursor: pointer;
        }
    }

    // Fallback for browsers without focus-within support (IE11).
    &__link:focus {
        text-decoration: underline;
    }

    &:focus-within &__link:focus {
        text-decoration: none;
    }

    /* 
     * Link in title
     */
    &:not(#{&}--link-stretched) &__link {
        position: relative;

        &::before {
            background-color: $opds-color-divider-default;
            bottom: 0;
            content: '';
            height: $opds-size-hairline;
            left: $opds-space-small;
            position: absolute;
            right: $opds-space-small;
        }

        &:focus::after {
            @include pseudo-outline;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:focus,
        &:hover {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-hover-default
            );
            outline: none;
        }

        &:active {
            background-color: darken-relative(
                $opds-color-background-default,
                $opds-misc-alpha-active-default
            );
            outline: none;
        }
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &:not(#{&}--link-stretched) &__link::before {
            left: $opds-space-default;
            right: $opds-space-default;
        }
    }

    &--with-image:not(#{&}--link-stretched) &__link:focus::after {
        border-radius: 0;
    }

    /* 
     * Stretched link
     */
    &--link-stretched &__link {
        &:focus {
            outline: none;
        }

        &::after {
            border-radius: $opds-border-radius-default;
            bottom: 0;
            content: '';
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    /* 
     * Content
     */
    &__content {
        color: $opds-color-text-default;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        letter-spacing: $opds-letter-spacing-default;
        line-height: $opds-line-height-default;
        margin: $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            margin: $opds-space-default;
        }
    }

    &:not(#{&}--linked) &__title + &__content,
    &--link-stretched#{&}--linked &__title + &__content {
        margin-top: 0;
    }

    /* 
     * Image
     */
    &__image {
        order: -1;

        img {
            display: block;
            height: auto;
            width: 100%;
        }
    }
}
