@import '@op/opds-tokens/lib/web/op/functions';
@import '@op/opds-tokens/lib/web/op/tokens';

.ds-action {
    align-items: center;
    border: 0;
    color: $opds-color-text-link;
    cursor: pointer;
    display: inline;
    font-family: inherit;
    font-size: $opds-font-size-default;
    line-height: $opds-line-height-default;
    padding: 0;
    text-align: left;
    text-decoration: none;

    &#{&} {
        // overrides <a> element style
        background: none;

        // overrides <button> element style (theme's custom-main.css)
        font-weight: $opds-font-weight-medium;
    }

    &--small {
        font-size: $opds-font-size-small;
        line-height: $opds-line-height-small;
    }

    &--medium {
        font-size: $opds-font-size-medium;
    }

    &--large {
        font-size: $opds-font-size-large;
        line-height: $opds-line-height-large;
    }

    &:disabled,
    &--disabled,
    &--non-interactive {
        cursor: default;
        pointer-events: none;
    }

    &--inline {
        font-size: inherit;
        line-height: inherit;
    }

    &--inline:not(#{&}--external):not(button),
    &__label {
        background-image: linear-gradient(
            to right,
            $opds-color-border-input-hover 0%,
            $opds-color-border-input-hover 100%
        );
        background-position: bottom;
        background-repeat: no-repeat;
    }

    &__label {
        background-size: 0;
        margin-right: $opds-space-xxxs + $opds-size-icon-small;
    }

    &--medium &__label,
    &--large &__label {
        margin-right: $opds-space-xxxs + $opds-size-icon-default;
    }

    // overrides <a> element style
    &#{&}--inline,
    &--inline &__label {
        background-size: 100% $opds-size-hairline;
    }

    &--inline:hover,
    &--inline:focus,
    &:hover #{&}__label,
    &:focus #{&}__label {
        background-size: 100% $opds-size-tiny;
    }

    &:focus {
        outline: $opds-width-outline-focus $opds-style-outline-focus
            $opds-color-outline-focus;
        outline-offset: $opds-size-tiny;
    }

    &__icon {
        margin-left: -$opds-size-icon-small;
        margin-top: gu(-0.5); // adapt to whitespace around icons
        vertical-align: middle;
    }

    &--medium &__icon,
    &--large &__icon {
        margin-left: -$opds-size-icon-default;
    }

    &--inline &__icon {
        margin-top: 0;
    }

    &#{&}--disabled {
        color: $opds-color-text-disabled;
        text-decoration: $opds-text-decoration-disabled;
    }

    &--disabled:hover #{&}__label,
    &--disabled:focus #{&}__label {
        background-size: 0;
    }

    &--disabled:focus {
        outline: none;
    }

    &--icon-left {
        flex-direction: row-reverse;
    }

    &--icon-left &__icon {
        margin-left: 0;
        margin-right: -$opds-size-icon-small;
    }

    &--icon-left &__label,
    &--inline:not(#{&}--external) &__label {
        margin-right: 0;
    }

    &--icon-left &__label {
        margin-left: $opds-size-icon-small + $opds-space-xxxs;
    }
}
