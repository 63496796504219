@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-modal--open {
    // This class is added to document body to disable page scrolling when a modal is open
    overflow: hidden;
}

.ds-modal__overlay {
    background-color: $opds-color-background-overlay-disabled;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    overflow: auto;
    padding: $opds-space-small;
    position: fixed;
    top: 0;
    transition: opacity 250ms ease-in-out;
    width: 100%;
    z-index: 1000;

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        padding: $opds-space-large;
    }

    &--enter {
        opacity: 1;
    }

    &--exit {
        opacity: 0;
    }

    &--large {
        padding: 0;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding-bottom: $opds-space-xxxl;
            padding-top: $opds-space-xxxl;
        }

        @media only screen and (min-width: $opds-breakpoint-xl-min) {
            padding-bottom: $opds-space-xxxxl;
            padding-top: $opds-space-xxxxl;
        }
    }
}

.ds-modal {
    background: $opds-color-background-default;
    border-radius: $opds-border-radius-default;
    box-shadow: $opds-shadow-modal-default;
    display: flex;
    flex-direction: column;
    height: auto;
    margin: auto;
    min-width: gu(74);
    outline: none;
    overflow: hidden;
    width: 100%;

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        width: 8 / 12 * 100%; // grid columns
    }

    @media only screen and (min-width: $opds-breakpoint-lg-min) {
        max-width: gu(162);
        width: 6 / 12 * 100%; // grid columns
    }

    &:focus {
        outline: none;
    }

    &__header {
        align-items: flex-start;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        width: 100%;
    }

    &__title {
        color: $opds-color-text-large;
        font-size: $opds-font-size-large;
        font-weight: $opds-font-weight-bold;
        line-height: $opds-line-height-default;
        margin: $opds-space-default 0;
        padding: 0 $opds-space-default;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            font-size: $opds-font-size-6;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-large;
        }

        &#{&}:not(:last-child) {
            padding-right: 0;
        }
    }

    &__close-button {
        margin: $opds-space-xs;
    }

    &__body {
        color: $opds-color-text-default;
        flex: 1 1 auto;
        font-size: $opds-font-size-default;
        font-weight: $opds-font-weight-default;
        line-height: $opds-line-height-default;
        margin: 0 0 $opds-space-default;
        max-height: 100%;
        overflow-y: auto;
        padding: 0 $opds-space-default 0 $opds-space-default;
        position: relative;

        // Prevent focus trap. Issue OPDS-1842
        .ds-option input {
            height: $opds-size-hairline;
        }
    }

    &__footer {
        display: flex;
        flex-shrink: 0;
        margin-bottom: $opds-space-default;
        padding: 0 $opds-space-default;
    }

    &--large {
        margin-top: 0;
        min-height: 100%;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            max-width: gu(228);
            min-height: auto;
            width: 10 / 12 * 100%; // grid columns
        }

        @media only screen and (min-width: $opds-breakpoint-lg-min) {
            width: 8 / 12 * 100%; // grid columns
        }
    }

    &--large #{&}__title,
    &--large #{&}__body,
    &--large #{&}__footer {
        padding: 0 $opds-space-small;

        @media only screen and (min-width: $opds-breakpoint-sm-min) {
            padding: 0 $opds-space-default;
        }
    }
}
