@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

$segment-width: gu(12); // From specification

.active {
    font-weight: bold;
}

.ds-pagination {
    display: inline-flex;
    justify-content: space-between;

    &__button-container {
        display: flex;

        button + button {
            margin-left: $opds-space-xxs;
        }

        &__next--no-page-controls {
            margin-left: $opds-space-small;
        }
    }

    &__list {
        &#{&} {
            align-items: center;
            display: flex;
            margin: 0 $opds-space-small;
            padding-left: 0;
        }

        &#{&} li {
            margin-bottom: 0;
            margin-left: -$opds-border-width-default; // For displaying outline correctly

            &:first-of-type {
                margin-left: 0;
            }

            &:first-of-type button {
                border-bottom-left-radius: $opds-border-radius-small;
                border-top-left-radius: $opds-border-radius-small;
            }

            &:last-of-type button {
                border-bottom-right-radius: $opds-border-radius-small;
                border-top-right-radius: $opds-border-radius-small;
            }

            &::before {
                content: none;
            }
        }

        &--indicators {
            margin-left: $opds-space-default;
            margin-right: $opds-space-default;

            li {
                &:first-child div {
                    margin-left: 0;
                }

                &:last-child div {
                    margin-right: 0;
                }
            }
        }
    }

    &__list--no-arrow-controls {
        margin: 0;
    }

    &__list__item {
        border-radius: 0;
        color: $opds-color-text-button-default;
        font-size: $opds-font-size-small;
        font-weight: $opds-font-weight-bold;
        line-height: $opds-line-height-micro;
        min-width: $segment-width + $opds-border-width-default; // To compensate minus margin overlapping
        padding: bordered($opds-space-xs) 0;

        &--active {
            color: $opds-color-palette-black;
        }

        &--active,
        &:active,
        &:focus,
        &:hover {
            border-bottom: $opds-size-tiny solid $opds-color-indicator-default;
            padding-bottom: bordered(
                $opds-space-xs - $opds-size-hairline
            ); // So that the button height doesn't increase
        }

        &--indicator#{&}--active {
            background-color: $opds-color-indicator-default;
            border-color: $opds-color-indicator-default;
        }

        &:focus {
            outline-offset: -$opds-border-width-default * 2;
        }

        &--ellipsis {
            border-bottom: $opds-border-width-default solid
                $opds-color-border-disabled;
            border-top: $opds-border-width-default solid
                $opds-color-border-disabled;
            color: $opds-color-text-disabled;
            pointer-events: none;
            text-align: center;
        }

        &--indicator {
            background-color: $opds-color-background-progressbar-track;
            border: $opds-border-width-default solid
                $opds-color-border-progressbar-track-bottom;
            border-radius: gu(3); // From specification
            border-top-color: $opds-color-border-progressbar-track-default;
            height: gu(2); // From specification
            margin-right: $opds-space-xs;
            min-width: gu(2); // From specification
            padding: 0;
            pointer-events: none;
        }

        .ds-pagination--small & {
            font-size: $opds-font-size-micro;
            padding-bottom: bordered($opds-space-xxs);
            padding-top: bordered($opds-space-xxs);

            &--active,
            &:hover {
                padding-bottom: bordered(
                    $opds-space-xxs - $opds-size-hairline
                ); // So that the button height doesn't increase
            }
        }

        .ds-pagination--large & {
            font-size: $opds-font-size-default;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-default;
            padding-bottom: bordered($opds-space-xs);
            padding-top: bordered($opds-space-xs);

            &--active,
            &:active,
            &:focus,
            &:hover {
                padding-bottom: bordered(
                    $opds-space-xs - $opds-size-hairline
                ); // So that the button height doesn't increase
            }
        }

        .ds-pagination--hero & {
            font-size: $opds-font-size-default;
            font-weight: $opds-font-weight-medium;
            line-height: $opds-line-height-default;
            padding-bottom: bordered($opds-space-xs);
            padding-top: bordered($opds-space-xs);

            &--active,
            &:active,
            &:focus,
            &:hover {
                padding-bottom: bordered(
                    $opds-space-xs - $opds-size-hairline
                ); // So that the button height doesn't increase
            }

            @media only screen and (min-width: $opds-breakpoint-sm-min) {
                font-size: $opds-font-size-medium;
                line-height: $opds-line-height-large;
                padding-bottom: bordered($opds-space-small);
                padding-top: bordered($opds-space-small);

                &--active,
                &:active,
                &:focus,
                &:hover {
                    padding-bottom: bordered(
                        $opds-space-small - $opds-size-hairline
                    ); // So that the button height doesn't increase
                }
            }
        }
    }

    &__list li:first-child :not(.ds-pagination__list__item--indicator) {
        min-width: $segment-width; // To not compensate non applied minus margin
    }
}
