@import '@op/opds-tokens/lib/web/op/tokens';
@import '@op/opds-tokens/lib/web/op/functions';

.ds-button-row {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: $opds-space-small 0;

    > .ds-button {
        flex: 0 0 auto;
        margin: $opds-space-xxs 0;
    }

    @media only screen and (min-width: $opds-breakpoint-sm-min) {
        &--unstacked {
            flex-flow: row wrap;
            margin: (-$opds-space-xxs) (-$opds-space-xs);
            padding: $opds-space-default 0;

            > .ds-button {
                margin: $opds-space-xxs $opds-space-xs;

                // To address issue https://oppjira.eficode.com/browse/OPDS-2787
                &:not(.ds-button--compact):not(.ds-button--minified) {
                    min-width: gu(32);
                }
            }
        }

        &--primary#{&}--unstacked {
            flex-direction: row-reverse;
            justify-content: space-between;

            > .ds-button--finish,
            > .ds-button--primary {
                order: -2;
            }

            > .ds-button--alternate {
                order: -1;
            }
        }
    }

    @media only screen and (min-width: $opds-breakpoint-md-min) {
        &--unstacked {
            > .ds-button:not(.ds-button--compact):not(.ds-button--minified) {
                min-width: gu(40);
            }
        }
    }
}
